<i18n locale="th" lang="yaml" >
page.title : "งานซ่อมที่เสร็จสิ้น"
page.description : "รายการของ ใบงานที่ได้ดำเนินการเสร็จสิ้นแล้ว"
</i18n>
<template>
	<div class="page">
		<div class="page-action-right">
			<a-button icon="left" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
		<my-page-header
			:title="$t('page.title',{ name: drone.serial_no })"
			:description="$t('page.description',{ name: drone.serial_no })"/>

		<a-card :bordered="false">
			<ServiceTable
				bordered
				:row-key="record => record.id"
				:data-source="serviceDataList"
				:pagination="pagination"
				:loading="loading"
				:hide-columns="['drone']"
				@change="handleTableChange"/>
		</a-card>
	</div>
</template>

<script>
import ServiceTable from "@components/service/ServiceTable.vue"
import ApiError from "@utils/errors/ApiError"
import axios from "axios"
import {fulfillServiceCardData} from "@utils/objectUtil"

export default {
	components : {
		ServiceTable ,
	} ,
	props : {
		droneData : {
			type : Object,
			default : () => []
		}
	} ,
	data() {
		return {
			serviceDataList : [],
			loading : false,
			pagination : {
				pageSize : 10 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : 'serial_no',
				order : 'descend',
			} ,
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		}
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		fetchData(toPage=null) {
			if (this.filterName && !this.filterValue) {
				return;
			}
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : toPage,
				sort_field : this.sort.field ,
				sort_order : this.sort.order ,
			}
			const filter = {
				drone_id : this.drone.id,
				status_list : ['close']
			};

			this.loading = true;
			axios.get("/api/services/search",{params : {search,filter}}).then((response)=>{
				const page = response.data.data.pagination
				const bucket = response.data.data.bucket

				this.serviceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,bucket)
				})

				this.pagination.current = toPage
				this.pagination.total = page.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.columnKey;
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		} ,
		goBack() {
			this.$open({name: 'drone/view' , params: {serial_no : this.drone.serial_no}})
		}
	}
}
</script>
